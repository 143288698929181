<template>
  <div
    :class="[
      'session',
      {
        'session--scaled': settings.isScaled,
        'session--sweepstake': isSweepstakesEnabled,
      },
    ]"
  >
    <game-message v-if="isMessageShown || isGameFrameError" v-bind="message" is-on-top>
      <v-button
        v-if="isMessageShown && !isGameFrameError"
        class="session__button"
        tag-name="button"
        variant="primary"
        type="submit"
        has-texture
        @click="handleRedirectToLobby"
      >
        {{ _("session.button-text") }}
      </v-button>
      <template v-if="!isGameUnavailable && isGameFrameError">
        <div class="session__error-steps">
          <div>
            <div>1. {{ _(`game-frame.incognito-step1`) }}</div>
            <div class="session__code-wrapper">
              <code class="session__code">chrome://settings/cookies</code>
            </div>
          </div>
          <div>2. {{ _(`game-frame.incognito-step2`) }}</div>
        </div>
        <div class="session__error-steps session__error-steps--big-font">
          {{ _("game-frame.iframe-incognito-title2") }}
        </div>
        <v-button
          v-if="isGameFrameError"
          class="session__button-game"
          tag-name="button"
          variant="primary"
          has-texture
          type="submit"
          @click="handleOpenGame"
        >
          {{ _("game-frame.incognito-open-game-button") }}
        </v-button>
      </template>
    </game-message>
    <iframe
      v-show="isGameShown && !isGameFrameError && !isGameUnavailable"
      ref="gameIframe"
      v-bind="$attrs"
      :src="src"
      class="session__frame"
      :title="settings.title"
      :scrolling="settings.isScrolling"
      :style="settings.iframeStyle"
      frameborder="0"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

// Components
import GameMessage from "@/components/Game/GameMessage";
import routeMixin from "@/mixins/route";
import { VButton } from "@/components/Helpers/Buttons";
import { get } from "lodash-es";

export default {
  name: "GameFrame",

  components: {
    GameMessage,
    VButton,
  },
  mixins: [routeMixin],

  inheritAttrs: false,

  props: {
    src: {
      type: URL,
      default: null,
    },

    settings: {
      type: Object,
      default: () => ({}),
    },
    isGameFrameError: {
      type: Boolean,
      default: false,
    },
    isGameUnavailable: {
      type: Boolean,
      default: false,
    },
    messageErrorFrame: {
      type: Array,
      default: () => ["null-session"],
    },
  },
  data() {
    return {
      default: {
        message: { translates: ["null-session"] },
        iconSettings: { size: 64 },
      },

      messageMap: {
        landscape: {
          translates: ["landscape", "rotate"],
          icon: "rotation",
        },

        portrait: {
          translates: ["portrait", "rotate"],
          iconSettings: { rotate: -90 },
          icon: "rotation",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
      currentItemProvider: "providers/currentItem",
      sessionUrl: "session/url",
    }),

    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      isLandscapeMode: (state) => state.app.isLandscapeMode,
    }),

    gameMode() {
      return this.isGameShown
        ? {
            isLandscape: this.settings.isLandscapeMode && !this.isLandscapeMode,
            isPortrait: this.settings.isPortraitMode && this.isLandscapeMode,
          }
        : {};
    },

    isGameShown() {
      return !!this.src;
    },

    isMessageShown() {
      return !this.isGameShown || this.gameMode.isLandscape || this.gameMode.isPortrait;
    },

    message() {
      const msg = {
        ...{ translates: this.messageErrorFrame },
        ...this.messageMap[
          this.gameMode.isLandscape ? "landscape" : this.gameMode.isPortrait ? "portrait" : null
        ],
      };

      msg.iconSettings = {
        ...this.default.iconSettings,
        ...msg.iconSettings,
      };
      return msg;
    },
  },

  mounted() {
    const iframe = this.$refs["gameIframe"];

    if (!iframe) {
      return;
    }

    const controller = new AbortController();

    iframe.addEventListener(
      "load",
      () => {
        try {
          const searchParams = new URLSearchParams(iframe.contentWindow.location.search);
          if (searchParams.get("forceGameExit")) {
            this.goHome();
          }
        } catch (error) {
          console.warn("Unable to access iframe's content window:", error);
        }
      },
      { signal: controller.signal }
    );

    this.$once("hook:beforeDestroy", () => controller.abort());
  },

  methods: {
    handleRedirectToLobby() {
      this.goToGames(false);
    },
    handleOpenGame() {
      window.open(this.sessionUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.session {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-black-color;
  position: relative;
  overflow: hidden;

  &--sweepstake {
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      margin-bottom: 40px;
    }
  }

  &__frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 10;
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
  }

  /** Fixed */

  &--scaled &__frame {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  &__error-steps {
    margin-top: 50px;
    text-align: center;
    font-size: $font-md-size;
    padding: 0 2em;

    &--big-font {
      font-size: 22px;
    }
  }

  &__button-game {
    margin-top: 50px;
    color: $text-color;
    font-family: $second-font-family;
    font-size: $font-md-size;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 180px;
    height: 40px;
  }

  &__code {
    background-color: white;
    color: black;
    font-size: $font-sm-size;
    border-radius: 4px;
    padding: 5px;
  }

  &__code-wrapper {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  &__button {
    position: relative;
    bottom: -20%;
    color: $text-color;
    font-family: $second-font-family;
    cursor: pointer;
    z-index: 15;
    text-transform: uppercase;
    font-size: $font-sm-size;
    width: 190px;
    height: 45px;
  }
}
</style>
