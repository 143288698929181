import { call } from "./client";

/**
 * @typedef {Object} Game
 * @property {string} id
 * @property {string} name
 * @property {number} brandId
 * @property {Array<string>} tagsIds
 */
/**
 * @returns {Promise<Array<Game>>}
 */
export async function fetchGames() {
  const response = await call("Game.list");

  return response.list ? response.list : [];
}

export async function updateGameFavoriteStatus(id, isFavorite) {
  const response = await call("Game.updateFavoriteStatus", {
    game_id: id,
    is_favorite: isFavorite,
  });

  return response?.gameId || null;
}

export async function fetchGamesIds() {
  const response = await call("Game.getRecommendations");

  if (!response) {
    return [];
  }

  if (!Array.isArray(response.games)) {
    throw new Error("games must be array");
  }

  return response.games || [];
}

export async function fetchNoWagerGames() {
  return await call("Game.listNoWager");
}

export async function fetchNoJackpotGames() {
  return await call("Game.listNoJackpot");
}

export async function fetchRedeemProviderBalance() {
  const res = await call("Game.redeemProviderBalance");

  if (!res || res instanceof Error) {
    return null;
  }

  return res;
}
