import { startCase } from "lodash-es";

// Constants
import { MOBILE_TAGS, TAG_MOBILE, TAG_MOBILE_UPPER, TECHNICAL_TAGS } from "../constants/game";

/**
 * @param {Object} obj
 * @param {string} key
 * @param {(string, null)} subKey
 * @description Increase object value by key
 */
export const incObjectValue = (obj, key, subKey = null) => {
  const num = (obj[key] ? (subKey ? obj[key][subKey] : obj[key]) || 0 : 0) + 1;
  return subKey
    ? obj[key]
      ? (obj[key][subKey] = num)
      : (obj[key] = { [subKey]: num })
    : (obj[key] = num);
};

/**
 * @param {Object} game
 * @return {boolean}
 * @description is the Mobile game!?
 */
export const isMobileGame = (game) => {
  for (const tag of game.tags) {
    if (MOBILE_TAGS.has(tag.name)) {
      return true;
    }
  }
  return false;
};

/**
 * @param {Object} game
 * @return {boolean}
 * @description is the Desktop game!?
 */
export const isDesktopGame = (game) => {
  for (const tag of game.tags) {
    if (tag.name === TAG_MOBILE || tag.name === TAG_MOBILE_UPPER) {
      return false;
    }
  }
  return true;
};

/**
 * @param {Object} game
 * @param {boolean} isMobile
 * @param {(number, null)} currentProviderId
 * @param {(number, null)} currentBrandId
 * @param {(string, null)} currentTagId
 * @param {string} searchQuery
 * @param isFavoriteFilter
 * @param isRecommendationFilter
 * @param recommendedGamesId
 * @returns {boolean}
 * @description Is hide game?!
 */
export const isGameHidden = (
  game,
  isMobile,
  currentProviderId,
  currentBrandId,
  currentTagId,
  searchQuery,
  isFavoriteFilter,
  isRecommendationFilter,
  recommendedGamesId
) => {
  if (!game.enabled) {
    return true;
  }

  if (isRecommendationFilter) {
    return (
      (isMobile && !game.isMobile) ||
      (!isMobile && !game.isDesktop) ||
      (searchQuery && !game.lowerCaseName.includes(searchQuery)) ||
      !recommendedGamesId.includes(game.originalId)
    );
  }

  if (isFavoriteFilter) {
    return (
      (isMobile && !game.isMobile) ||
      (!isMobile && !game.isDesktop) ||
      (searchQuery && !game.lowerCaseName.includes(searchQuery)) ||
      (isFavoriteFilter && !game.isFavorite)
    );
  }

  return (isMobile && !game.isMobile) ||
    (!isMobile && !game.isDesktop) ||
    game.providerId !== currentProviderId ||
    (currentBrandId !== null && currentBrandId !== game.brandId) ||
    (searchQuery && !game.lowerCaseName.includes(searchQuery))
    ? true
    : currentTagId
    ? !game.tagsIds.includes(currentTagId)
    : false;
};

/**
 * @param {Array<Object>} list
 * @return {{countOfGamesBy: {brands: {}, providers: {}, tags: {}}, games: Array}}
 * @description Analyze games...
 */
export const analyzeGames = (list = []) => {
  const countOfGamesBy = { providers: {}, brands: {}, tags: {} };
  const games = [];

  for (const game of list) {
    const { stringId, gameSectionId: brandId, gameProviderId: providerId } = game;
    const tagsIds = [];

    // Calculate count of games by providers & brand & tags
    incObjectValue(countOfGamesBy.brands, brandId, providerId);
    incObjectValue(countOfGamesBy.providers, providerId, "gamesCount");

    // Calculate tagsIds and count of games by tags.
    if (game.tags) {
      game.tags.forEach((tag) => {
        incObjectValue(countOfGamesBy.tags, tag.id);

        tagsIds.push(tag.name.toLowerCase());

        switch (tag.name) {
          case "jackpot":
            game.is_jackpot = true;
            break;

          case "new":
            game.is_new = true;
            break;

          default: // do nothing
        }
      });
    }

    // Save the first `game.string_id` by provider_id
    if (!countOfGamesBy.providers[providerId].game) {
      countOfGamesBy.providers[providerId].game = stringId;
    }

    // Game restructuring
    games.push({
      id: stringId,
      originalId: game.id,
      name: startCase(game.name),
      brandId: brandId,
      providerId: providerId,
      description: game.description,
      lowerCaseName: game.name.toLowerCase(),
      isWalletIntegration: game.walletIntegration,
      enabled: game.enabled,
      pic: game.pic,
      tagsIds,
      isDesktop: isDesktopGame(game),
      isMobile: isMobileGame(game),
      isFavorite: game?.isFavorite,
      isNew: game?.is_new,
    });
  }

  return {
    games,
    countOfGamesBy,
  };
};

/**
 * @param {Array<Object>} games
 * @return {{all: number, brands: {}, providers: {}, tags: {}}}
 * @description Calculate count of games...
 */
export const calcGames = (games) => {
  const cntOf = {
    all: 0,
    tags: {},
    brands: {},
    providers: {},
  };

  if (games) {
    cntOf.all = games.length;

    for (const game of games) {
      incObjectValue(cntOf.brands, game.brandId);
      incObjectValue(cntOf.providers, game.providerId);
      for (const tag of game.tagsIds) {
        incObjectValue(cntOf.tags, tag);
      }
    }
  }

  return cntOf;
};

/**
 * @param {Array<Object>} tags
 * @return {Array<Object>}
 * @description Sort tags by priority.
 */
export const sortTags = (tags) => {
  return Array.isArray(tags)
    ? tags.sort((a, b) => {
        return (b.priority || 0) - (a.priority || 0);
      })
    : tags;
};

/**
 * @param {Array<Object>} tags
 * @param {Object} countOfGames
 * @return {Array<Object>}
 * @description Sort tags by priority.
 */
export const prepareTags = (tags, countOfGames) => {
  const computedTags = [];

  for (const tag of tags) {
    const lowerCaseName = tag.name.toLowerCase();

    if (!TECHNICAL_TAGS.has(lowerCaseName) && countOfGames[tag.id]) {
      computedTags.push({
        ...tag,
        lowerCaseName,
      });
    }
  }

  return computedTags;
};

/**
 * @param {Array<Object>} tags
 * @param {Object<Object>} countOf
 * @return {Array<Object>}
 * @description Add games count to tags.
 */
export const addGamesCountToTags = (tags, countOf) => {
  return tags
    ? tags.map((tag) => ({
        ...tag,
        gamesCount: countOf ? countOf.tags[tag.lowerCaseName] || 0 : 0,
      }))
    : [];
};

/**
 *
 * @param {{brands: {}, providers: {}, tags: {}}} countOfGamesByProviders
 * @return {(number, null)}
 * @description Get current providerId.
 */
export const getCurrentProviderId = (countOfGamesByProviders) => {
  const providers = Object.keys(countOfGamesByProviders);
  let id = null;

  for (let i = 0; i < providers.length; i++) {
    if (countOfGamesByProviders[providers[i]].gamesCount > 1) {
      id = Number.parseInt(providers[i]);
      break;
    }
  }
  return id;
};
