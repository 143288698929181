<template>
  <base-modal v-if="isShow" :title="_('please_note')" class="modal" :content="{}">
    <template #body>
      <svg
        viewBox="0 0 43 40"
        width="1.0625em"
        height="1em"
        class="modal__icon"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          d="M21.46 11.01a2.5 2.5 0 0 0-2.5 2.5c0 1.4.7 8.93.83 11.44 0 .97.7 1.67 1.67 1.67.98 0 1.68-.7 1.68-1.67.27-2.37.83-9.76.83-11.43a2.5 2.5 0 0 0-2.5-2.5Zm0 18.4a2.2 2.2 0 0 0-2.23 2.23 2.2 2.2 0 0 0 2.23 2.23 2.2 2.2 0 0 0 2.23-2.23 2.2 2.2 0 0 0-2.23-2.23Zm20.63 1.25C40.98 28.85 27.32 4.04 26.76 3.21A5.94 5.94 0 0 0 21.46 0a5.94 5.94 0 0 0-5.3 3.2C15.62 4.05 1.82 28.86.85 30.67A6.22 6.22 0 0 0 6.14 40h30.65a6.22 6.22 0 0 0 5.3-9.34Zm-5.16 5.02H6c-.97 0-1.8-.84-1.8-1.81 0-.42 0-.7.27-.98.28-.56 15.33-27.32 15.47-27.6.28-.7.84-1.1 1.53-1.1.7 0 1.26.4 1.68.97.14.28 15.05 27.04 15.47 27.6.14.27.27.55.27.97-.13 1.11-.97 1.95-1.95 1.95Z"
        />
      </svg>
      <span class="modal__text">
        {{ _("wallet_provider_text_1") }}
      </span>
      <span class="modal__text">
        {{ _("wallet_provider_text_2") }}
      </span>
    </template>
    <template #footer>
      <v-button class="back-button" variant="accept" has-texture @click="handleOk">
        {{ _("jackpot-modal.ok") }}
      </v-button>
    </template>
  </base-modal>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";
import { mapGetters, mapMutations, mapState } from "vuex";
import { get } from "lodash-es";
import routeMixin from "@/mixins/route";
import gameFilter from "@/mixins/game-filter";

export default {
  name: "WarningWalletModal",
  components: {
    VButton,
    BaseModal,
  },
  mixins: [routeMixin, gameFilter],

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },

    onClose: {
      type: Function,
      default: () => undefined,
    },
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),
    ...mapState({
      ui: (state) => get(state, "ui.sessionParams"),
      textContent: (state) => get(state, "lobby.params.contacts", ""),
    }),
  },
  methods: {
    ...mapMutations({
      handleCloseWarningModal: "ui/hideWarningWalletModal",
    }),
    async handleOk() {
      this.handleCloseWarningModal();
      this.openGameById(this.ui.gameId, this.ui.isDemoMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__icon {
    font-size: 4rem;
    padding: 2rem;
  }

  &__text {
    &:nth-child(1) {
      font-weight: bold;
    }
    margin-top: 0.25rem;
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  max-height: 80%;
  background: none;
  font-size: 16px;
}

:deep .modal__window {
  max-width: 500px;
  max-height: 400px;
  padding: 40px;
  // overflow-y: scroll;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 190px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media screen and (max-width: 720px) {
    width: 240px;
    margin-top: -26px;
  }
}
</style>
