<template>
  <div
    :class="['game', { 'game--airclub-theme': isAirClubTheme }]"
    :data-id="game.id"
    :data-hidden="game.isHidden"
    :data-provider="game.providerId"
    @click="handleGameClick"
  >
    <div class="game__container">
      <span v-if="game.isNew" class="game__tooltip">New</span>

      <div class="game__overlay">
        <div class="game__description">
          {{ game.description }}
        </div>
        <v-button
          :class="['game__button', `game__button--${mode}`]"
          :variant="mode"
          @click="handleButtonClick"
        >
          {{ caption }}
        </v-button>
        <div v-if="hasUser" class="game__button-star-wrapper">
          <StarButton
            variant="heart"
            :on-click="handleGameFavorite"
            :is-game-favorite="isFavorite"
          />
        </div>
      </div>
      <img class="game__image" :src="imageSource" :alt="game.description" />
      <!--      <div class="game__xmas" />-->
      <div v-if="hasUser && isFavorite" class="game__button-star-wrapper">
        <StarButton variant="heart" :on-click="handleGameFavorite" :is-game-favorite="isFavorite" />
      </div>
      <div class="game__background" />
    </div>
    <div class="game__title">{{ game.name }}</div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

// Mixins
import routeMixin from "@/mixins/route";

// Utils
import { getGameImageUrl, getTheme, isImageCached } from "../../utils";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import StarButton from "@/components/Helpers/Buttons/StarButton";
import { gamesService } from "@/services/GamesService/GamesService";
import gameFilter from "@/mixins/game-filter";

const THEME = getTheme();

export default {
  name: "GameCard",
  components: {
    StarButton,
    VButton,
  },

  mixins: [routeMixin, gameFilter],
  props: {
    game: {
      type: Object,
      required: true,
    },

    isDemoMode: {
      type: Boolean,
      default: false,
    },

    clickEventName: {
      type: String,
      default: "on-game-click",
    },

    isGameFavorite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAirClubTheme: THEME === "airclub",
      isFavorite: this.game.isFavorite ?? false,
    };
  },

  computed: {
    ...mapGetters({
      translate: "l10n/translate",
      hasUser: "user/hasUser",
    }),

    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),

    mode() {
      return this.isDemoMode ? "demo" : "play";
    },

    caption() {
      return this.translate("game.play");
    },

    imageSource() {
      return this.game.isHidden
        ? isImageCached(getGameImageUrl(this.game.id))
          ? getGameImageUrl(this.game.id)
          : getGameImageUrl("GAME_IMAGE_NOT_CACHED")
        : getGameImageUrl(this.game.id);
    },
  },
  methods: {
    ...mapActions({
      setSessionParams: "ui/setSessionParams",
    }),
    ...mapMutations({
      handleShowWarningModal: "ui/showWarningWalletModal",
    }),
    async handleGameFavorite() {
      if (this.isMobile) {
        return this.handleGameClick();
      }

      this.isFavorite = !this.isFavorite;

      const updatedGameId = await gamesService.updateGameFavoriteStatus(
        this.game.originalId,
        !this.game.isFavorite
      );

      if (updatedGameId) {
        this.$store.commit("games/updateFavoriteItem", {
          originalId: updatedGameId,
          isFavorite: !this.game.isFavorite,
        });
      } else {
        this.$store.commit("games/updateFavoriteItem", {
          originalId: updatedGameId,
          isFavorite: this.game.isGameFavorite,
        });
      }
    },

    handleGameClick(event) {
      if (this.isMobile) {
        this.emitEvent(this.clickEventName, event);
      }
    },

    handleButtonClick(event) {
      this.emitEvent(this.clickEventName, event);
    },

    emitEvent(eventName, event) {
      const sessionParams = {
        gameOriginalId: this.game.originalId,
        gameIsFavorite: this.game.isFavorite,
        gameId: this.game.id,
        isDemoMode: this.isDemoMode,
      };

      if (this.game.isWalletIntegration) {
        this.handleShowWarningModal();
        this.setSessionParams(sessionParams);
      } else {
        this.$emit(eventName, event, sessionParams);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 120px;
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    width: 92px;
  }

  @media (orientation: landscape) and (max-height: 343px) {
    width: 150px;
  }

  @media (min-width: 1024px) {
    width: 120px;
  }

  &--airclub-theme {
    @media (min-width: 1024px) {
      width: 162px;

      .game__container {
        width: 162px;
        height: 162px;
      }
    }
  }

  &__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $game-card-bg;
    position: absolute;
    z-index: 1;
  }

  &__title {
    color: $text-color;
    display: inline-block;
    line-height: $font-xs-line;
    font-family: $base-font-family;
    text-transform: uppercase;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;

    @media (orientation: portrait) and (max-width: 1024px) {
      height: 30px;
      font-size: $font-xxs-size;
      line-height: $font-xs-line;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      height: 30px;
      font-size: $font-xxs-size;
      line-height: $font-xs-line;
    }

    @media (orientation: landscape) and (max-height: 343px) {
      width: 140px;
    }

    @media (min-width: 1025px) {
      width: 120px;
      height: 30px;
      font-size: $font-xxs-size;
      line-height: $font-xs-line;
    }
  }

  &__image {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 2;
  }

  &__description {
    color: $text-color;
    font-size: $font-xxs-size;
    font-family: $base-font-family;
    text-align: center;
    padding: 0 16px;
    box-sizing: border-box;
  }

  &__container {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    @media (orientation: portrait) and (max-width: 1024px) {
      width: 120px;
      height: 120px;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      width: 92px;
      height: 92px;
    }

    @media (orientation: landscape) and (max-height: 343px) {
      width: 150px;
      height: 150px;
    }

    @media (min-width: 1024px) {
      width: 120px;
      height: 120px;
    }
  }

  &__container:hover &__overlay {
    opacity: 1;
    box-shadow: inset 0 0 15px 5px $bg-dark-color;
  }

  &__overlay {
    display: none;
    z-index: 3;

    @media (min-width: 1025px) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      background-color: fade-out($bg-black-color, 0.25);
      position: absolute;
      opacity: 0;
    }
  }

  &__button {
    &--play,
    &--demo {
      width: auto;
      padding: 0 20px;
      height: 30px;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__button-star-wrapper {
    position: absolute;
    right: 6px;
    top: 8px;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    z-index: 4;
  }

  &__tooltip {
    right: 0;
    width: 50%;
    text-align: center;
    color: $white-color;
    text-shadow: 1px 1px fade-out($dark-color, 0.6);
    font-size: $font-md-size;
    line-height: $font-md-line;
    font-family: $base-font-family, $fallback-font-family;
    font-weight: bold;
    position: absolute;
    padding: 0 10px;
    cursor: default;
    z-index: 3;
    top: 0;
    background: $tomato-color;
    background: linear-gradient(to right, $coral-color 0%, $tomato-color 100%);
    -webkit-transform: rotate(45deg) translate3d(27%, -15px, 0);
    transform: rotate(45deg) translate3d(27%, -15px, 0);
  }

  &__xmas {
    position: absolute;
    pointer-events: none;
    inset: 0;
    z-index: 2;
    background: url("../xmas-decoration/assets/gamecard-decoration.png") center / cover;
  }
}
</style>
