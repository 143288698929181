export const SHOW_ALL = -1;
export const TAG_DEFAULT_ID = SHOW_ALL;

export const TAG_HTML = "html";
export const TAG_MOBILE = "mobile";
export const TAG_MOBILE_UPPER = "Mobile";
export const TAG_SWIPE_UP = "swipe-up-fix";
export const TAG_NO_SWIPE_UP = "disable-swipe-up-fix";
export const TAG_ONLY_PORTRAIT = "only-portrait";
export const TAG_ONLY_LANDSCAPE = "only-landscape";
export const TAG_AVIATOR = "aviator";
export const TAG_CHINESE = "Chinese New Year";

export const TAG_CHRISTMAS = "Christmas";

export const TAG_HALLOWEEN = "halloween";

export const TAG_FISHING = "fishing";

export const MOBILE_TAGS = new Set([TAG_HTML, TAG_MOBILE, TAG_MOBILE_UPPER]);
export const TECHNICAL_TAGS = new Set([
  TAG_HTML,
  TAG_MOBILE,
  TAG_SWIPE_UP,
  TAG_NO_SWIPE_UP,
  TAG_ONLY_PORTRAIT,
  TAG_ONLY_LANDSCAPE,
]);

export const displayJackpotsMap = {
  casino: true,
  slots: true,
};

export const providerIdMap = {
  games: "InBet",
  casino: "OCB.vegas",
  slots: "OCB.mascot",
  virtual: "InBet.Virtual.Games",
  sport: "BettingSoftware",
  live: "ViG",
  poker: "OCB.Poker",
  fish: "OCB2.fish",
  other: "Other",
};

export const brandIdMap = {
  fortune_panda: "fortune_panda",
  lasworks: "sport_book",
  mga_games: "mga_games",
  tvbet: "tvbet",
  btm: "BTM",
  sportBets: "SportBets",
  rub90: "Rub90",
  vig: "ViG",
  IGT: "igt",
  EGT: "egt",
  Kajot: "kajot",
  InBet: "InBet",
  NetEnt: "netent",
  Amatic: "amatic",
  Apollo: "apollo",
  Wazdan: "wazdan",
  Merkur: "merkur",
  Konami: "konami",
  Mascot: "mascot",
  Booongo: "booongo",
  Playson: "playson",
  Fishing: "fishing",
  Igrosoft: "igrosoft",
  Playtech: "playtech",
  Greentube: "greentube",
  Gaminator: "gaminator",
  Quickspin: "quickspin",
  Pragmatic: "pragmatic",
  Aristocrat: "aristocrat",
  OCB_Poker: "ocb_poker",
  Microgaming: "microgaming",
  Spadegaming: "spadegaming",
  Original_Spirit: "OriginalSpirit",
  Betting_Software: "BettingSoftware",
  InBet_Virtual: "InBet_Virtual_Games",
};

export const brandIdNumberMap = {
  InBet_Virtual: 119,
};
