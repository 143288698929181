var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'session',
    {
      'session--scaled': _vm.settings.isScaled,
      'session--sweepstake': _vm.isSweepstakesEnabled,
    },
  ]},[(_vm.isMessageShown || _vm.isGameFrameError)?_c('game-message',_vm._b({attrs:{"is-on-top":""}},'game-message',_vm.message,false),[(_vm.isMessageShown && !_vm.isGameFrameError)?_c('v-button',{staticClass:"session__button",attrs:{"tag-name":"button","variant":"primary","type":"submit","has-texture":""},on:{"click":_vm.handleRedirectToLobby}},[_vm._v(" "+_vm._s(_vm._("session.button-text"))+" ")]):_vm._e(),(!_vm.isGameUnavailable && _vm.isGameFrameError)?[_c('div',{staticClass:"session__error-steps"},[_c('div',[_c('div',[_vm._v("1. "+_vm._s(_vm._(`game-frame.incognito-step1`)))]),_c('div',{staticClass:"session__code-wrapper"},[_c('code',{staticClass:"session__code"},[_vm._v("chrome://settings/cookies")])])]),_c('div',[_vm._v("2. "+_vm._s(_vm._(`game-frame.incognito-step2`)))])]),_c('div',{staticClass:"session__error-steps session__error-steps--big-font"},[_vm._v(" "+_vm._s(_vm._("game-frame.iframe-incognito-title2"))+" ")]),(_vm.isGameFrameError)?_c('v-button',{staticClass:"session__button-game",attrs:{"tag-name":"button","variant":"primary","has-texture":"","type":"submit"},on:{"click":_vm.handleOpenGame}},[_vm._v(" "+_vm._s(_vm._("game-frame.incognito-open-game-button"))+" ")]):_vm._e()]:_vm._e()],2):_vm._e(),_c('iframe',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isGameShown && !_vm.isGameFrameError && !_vm.isGameUnavailable),expression:"isGameShown && !isGameFrameError && !isGameUnavailable"}],ref:"gameIframe",staticClass:"session__frame",style:(_vm.settings.iframeStyle),attrs:{"src":_vm.src,"title":_vm.settings.title,"scrolling":_vm.settings.isScrolling,"frameborder":"0"}},'iframe',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }