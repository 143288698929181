<template>
  <div id="app" role="application">
    <SvgRoot />
    <Loading v-if="isLoading" />
    <JackpotModal />
    <InfoModal
      v-if="isInfoModalOpen"
      :is-show-modal="isInfoModalOpen"
      :on-close="onCloseHelpModal"
    />
    <TermsOfUseModal v-if="hasUser && isSweepstakesEnabled" />
    <ContactsModal :is-show-contacts-modal="isModalShown" :on-close="onCloseContactsModal" />
    <WarningWalletModal :is-show="isModalWarningShown" />
    <SportBetsHistoryModal
      :is-open="isSportBetHistoryModalOpen"
      :on-close="onCloseSportBetHistoryModal"
    />
    <router-view />

    <template v-if="panicState.isPanic">
      <iframe class="iframe" :src="panicState.panicUrl" />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

// Utils
import { ViewportAnalyzer } from "@/utils/viewport-analyzer";

// Components
import Loading from "@/components/Helpers/Loading";
import SvgRoot from "@/components/Helpers/Icons/SvgRoot";
import JackpotModal from "@/components/Modal/JackpotModal";
import ContactsModal from "@/components/Modal/ContactsModal";
import InfoModal from "@/components/Modal/InfoModal";
import TermsOfUseModal from "@/components/Modal/TermsOfUseModal.vue";
import WarningWalletModal from "@/components/Modal/WarningWalletModal.vue";

import { get } from "lodash-es";
import { filterService } from "@/services/QueryFilterService/query-filter-service";
import SportBetsHistoryModal from "@/modules/SportBetHistory/components/SportBetHistoryModal.vue";

export default {
  components: {
    SportBetsHistoryModal,
    TermsOfUseModal,
    ContactsModal,
    SvgRoot,
    Loading,
    JackpotModal,
    WarningWalletModal,
    InfoModal,
  },

  data() {
    return {
      viewportAnalyzer: new ViewportAnalyzer(),
    };
  },

  computed: {
    ...mapGetters({
      panicState: "user/panicState",
      hasUser: "user/hasUser",
    }),
    ...mapState({
      isSweepstakesEnabled: (state) => get(state, "lobby.params.sweepstakes", false),
      isLoading: (state) => state.app.isLoading,
      isModalShown: (state) => state.ui.isContactsModalShown,
      isModalWarningShown: (state) => state.ui.isWarningWalletModalShown,
      isInfoModalOpen: (state) => state.ui.isInfoModalOpen,
      isSportBetHistoryModalOpen: (state) => state.ui.isBetHistoryModalShown,
    }),
  },

  created() {
    this.viewportAnalyzer.init((state) => {
      this.$store.commit("app/setIsMobile", state.isMobile);
      this.$store.commit("app/setIsLandscapeMode", state.isLandscapeMode);
    });
  },

  mounted() {
    if (!this.hasUser) {
      filterService.removeFilters();
    }
  },

  destroyed() {
    this.viewportAnalyzer.removeEventListeners();
  },

  methods: {
    ...mapMutations({
      onCloseContactsModal: "ui/hideContactsModal",
      onCloseSportBetHistoryModal: "ui/hideSportBetHistoryModal",
      onCloseHelpModal: "ui/hideInfoModal",
    }),
  },
};
</script>

<style lang="scss">
#app {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-x: hidden;
}

.iframe {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
  background: white;
  border: 0;
  outline: none;
}
</style>
