import {
  brandIdMap,
  brandIdNumberMap,
  providerIdMap as providerId,
  TAG_ONLY_LANDSCAPE,
  TAG_ONLY_PORTRAIT,
} from "../constants/game";
import { CASINO_PROVIDER_NAME, PLAY_N_GO_BRAND_NAME } from "@/constants";
import { IncognitoDetectorService } from "@/services/IncognitoDetectorService/IncognitoDetectorService";

export class GameFix {
  constructor() {
    this.name = null;
    this.tags = null;
    this.brand = null;
    this.provider = null;
    this.isMobile = false;
    this.iframeStyle = undefined;
    this.sessionUrl = null;
    this.wrapper = null;

    // Fixes
    this.isOnlyPortrait = false;
    this.isOnlyLandscape = false;
    this.aspectRatio = null;
    this.isGameFrameError = false;

    /**
     * Scrollable Iframe
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-scrolling
     */
    this.isScrollableIframe = "no"; // {("auto", "yes", "no")}
  }

  /**
   * @param {string} name
   * @param {string} stringId
   * @param {Array<Object>} tags
   * @param {(Object | null)} brand
   * @param {(Object | null)} provider
   * @description Load game data.
   */
  loadGameData({ name, string_id, tags }, brand = null, provider = null) {
    this.id = string_id;
    this.name = name;
    this.provider = provider;
    this.brand = brand;
    this.tags = tags;
  }

  /**
   * @param {Object} game
   * @param {(Object | null)} brand
   * @param {(Object | null)} provider
   * @description Load game data in project `theclub23`.
   */
  loadTheClubGame({ name, id, tagsIds }, brand = null, provider = null) {
    const currentBrand = brand
      ? {
          id: brand.id,
          name: brand.name,
          string_id: brand.stringId,
        }
      : null;

    const currentProvider = provider
      ? {
          id: provider.id,
          name: provider.name,
          string_id: provider.stringId,
        }
      : null;

    this.loadGameData({ name, string_id: id, tags: tagsIds }, currentBrand, currentProvider);
    this.checkIframeError();
  }

  // todo (game provider must fixed it)  Tmp Crunch
  checkIframeError() {
    if (this.provider?.name === CASINO_PROVIDER_NAME && this.brand?.name === PLAY_N_GO_BRAND_NAME) {
      IncognitoDetectorService.detectChrome().then((result) => {
        if (result.isPrivate) {
          const ua = navigator.userAgent;
          if (ua.match(/Chrome/)) {
            this.isGameFrameError = true;
          }
        } else {
          this.isGameFrameError = false;
        }
      });
    }
  }

  setGameSessionUrl(brand, session) {
    if (
      brand?.stringId === brandIdMap.rub90 ||
      brand?.stringId === brandIdMap.vig ||
      brand?.stringId === brandIdMap.sportBets ||
      brand?.stringId === brandIdMap.fortune_panda
    ) {
      this.sessionUrl = new URL(session);
    } else {
      this.sessionUrl = addUrlParam(session, "noFinger", "");
    }
  }

  /**
   * @param {boolean} isMobile
   * @description Analyze game...
   */
  analyzeGame(isMobile = this.isMobile) {
    if (isMobile) {
      // Games in landscape mode!
      if (
        [
          "50_dragons_html",
          "big_ben_html",
          "lucky_88_html",
          "miss_kitty_html",
          "red_baron_html",
          "queen_of_the_nile_2_html",
          "choy_sun_doa_html",
        ].includes(this.id)
      ) {
        this.isOnlyLandscape = true;
      }
      // Fixes by tag
      for (const tag of this.tags) {
        if (
          this.brand?.id === brandIdNumberMap.InBet_Virtual ||
          this.provider?.string_id === providerId.virtual ||
          this.brand?.string_id === brandIdMap.InBet_Virtual ||
          this.brand?.string_id === brandIdMap.btm ||
          this.brand?.string_id === brandIdMap.Betting_Software ||
          this.brand?.string_id === brandIdMap.sportBets ||
          this.brand?.string_id === brandIdMap.tvbet ||
          this.brand?.string_id === brandIdMap.mga_games ||
          this.brand?.string_id === brandIdMap.lasworks
        ) {
          this.isScrollableIframe = "yes";
        }
        switch (tag) {
          case TAG_ONLY_PORTRAIT:
            this.isOnlyPortrait = true;
            break;

          case TAG_ONLY_LANDSCAPE:
            this.isOnlyLandscape = true;
            break;

          default:
            break;
        }
      }
    }
  }

  /**
   * @return {{isPortraitMode: boolean, isLandscapeMode: boolean, style: (Object | undefined), title: null, isScrolling: string}}
   * @description Get iframe settings.
   */
  frameSettings() {
    return {
      title: this.name,
      isScrolling: this.isScrollableIframe,
      isLandscapeMode: this.isOnlyLandscape,
      isPortraitMode: this.isOnlyPortrait,
      style: this.iframeStyle,
    };
  }

  /**
   * @description resize iframe wrapper
   */
  resizeWrapper() {
    Object.assign(this.iframeStyle, resizeGameFrame(this.wrapper, this.aspectRatio));
  }

  /**
   * @description Add resize listener.
   */
  addResizeListener() {
    if (this.wrapper && this.aspectRatio > 0) {
      window.addEventListener("resize", this.resizeWrapper);
    }
  }

  /**
   * @description Remove resize listener.
   */
  removeResizeListeners() {
    window.removeEventListener("resize", this.resizeWrapper);
  }
}

/**
 * @param {HTMLElement} wrapper Game wrapper
 * @param {number} aspectRatio
 * @return {null | {width: (null|number), height: (number|null)}}
 * @description Get width/height by aspect ratio.
 */
export const resizeGameFrame = (wrapper, aspectRatio = 16 / 9) => {
  if (!wrapper || !aspectRatio) {
    return null;
  }

  const style = window.getComputedStyle(wrapper);
  const width = Number.parseInt(style.width, 10);
  const height = Number.parseInt(style.height, 10);
  const isWide = width / aspectRatio <= height;

  return {
    width: isWide ? null : height * aspectRatio,
    height: isWide ? width / aspectRatio : null,
  };
};

/**
 * @param {(string, null)} session
 * @param {string} key
 * @param {string} value
 * @return {null|URL}
 * @description Add search parameter.
 */
export const addUrlParam = (session, key = "noFinger", value = "") => {
  if (!session) {
    return null;
  }

  const url = new URL(session);

  if (key) {
    // Add key/value
    url.searchParams.append(key, value);
    // Remove empty value
    if (!value) {
      url.search = url.search.replace(`${key}=`, key);
    }
  }

  return url;
};
